import React, {FunctionComponent, ReactNode} from "react";
import GlobalStyle from "../styles/global-style";
import {graphql, useStaticQuery} from "gatsby";
import Header from "./header";
import {SiteMetadata} from "../utils/models";
import Navigation from "./navigation";
import Footer from "./footer";

interface LayoutProps {
  children: ReactNode;
  bigHeader?: boolean;
  lang?: string;
}

const Layout: FunctionComponent<LayoutProps> = ({children, bigHeader = true, lang = 'en'}) => {
  const data = useStaticQuery<SiteMetadata>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          topics
          menu {
            name
            path
          }
          footerMenu {
            name
            path
          }
          search
        }
      }
    }
  `);
  if (lang === 'en') {
    return (
      <>
        <GlobalStyle/>
        {bigHeader ? (
          <Header
            title={data.site.siteMetadata.title}
            description={'Hoosgood is a group of teachers'}
            topics={data.site.siteMetadata.topics}
            menu={data.site.siteMetadata.menu}
            search={data.site.siteMetadata.search}
          />
        ) : (
          <Navigation
            title={data.site.siteMetadata.title}
            menu={data.site.siteMetadata.menu}
            showSearch={data.site.siteMetadata.search}
            dark={true}
          />
        )}
        <main>
          {children}
        </main>
        <Footer menu={data.site.siteMetadata.footerMenu} owner={data.site.siteMetadata.title}/>
      </>
    );
  }
  if (lang === 'fr') {
    return (
      <>
        <GlobalStyle/>
        {bigHeader ? (
          <Header
            title={data.site.siteMetadata.title}
            description={`hoosgood est un regroupement de profs qui donnent des cours en ligne ou en personne. Les profs automatisent toute la gestion des horaires, listes d'attente, annulations, modifications et les paiements par carte de crédit.`}
            topics={data.site.siteMetadata.topics}
            menu={data.site.siteMetadata.menu}
            search={data.site.siteMetadata.search}
          />
        ) : (
          <Navigation
            title={data.site.siteMetadata.title}
            menu={data.site.siteMetadata.menu}
            showSearch={data.site.siteMetadata.search}
            dark={true}
          />
        )}
        <main>
          {children}
        </main>
        <Footer menu={data.site.siteMetadata.footerMenu} owner={data.site.siteMetadata.title}/>
      </>
    );
  }
};

export default Layout;
